<template>
  <div class="about">
          <heade currentrStr="adout"></heade>
    <div class="box bottom">
      <div>
        <p class="banner-title title-w">Hash Code 2022 is a wrap!</p>
        <p class="banner-detail title-w">
          描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述2013?
        </p>
        <p><a-button type="primary"> 按钮暂定 </a-button></p>
      </div>
      <div class="bottom-pic">
        <img
          width="100%"
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.net2006.com%2Fpics%2Fuploadimg%2F201907%2F201907161043444625.jpg&refer=http%3A%2F%2Fwww.net2006.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1666313117&t=ef754e7a3e13fae509139b4db808d0e8"
        />
      </div>
    </div>
     <div class="box bottom">
     
      <div class="bottom-pic  pic-left">
        <img
          width="100%"
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.net2006.com%2Fpics%2Fuploadimg%2F201907%2F201907161043444625.jpg&refer=http%3A%2F%2Fwww.net2006.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1666313117&t=ef754e7a3e13fae509139b4db808d0e8"
        />
      </div>
       <div>
        <p class="banner-title title-w">Hash Code 2022 is a wrap!</p>
        <p class="banner-detail title-w">
          描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述2013?
        </p>
        <p><a-button type="primary"> 按钮暂定 </a-button></p>
      </div>
    </div>
  </div>
</template>
<script>
import heade from '@/components/header.vue'
export default {
  data(){
    return {
    
    }
  },
  components: {
    heade
  },
  mounted(){

  }
};
</script>
<style scoped>
.about {
  
}
.box {
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
  padding: 50px 0;
}
.bottom {
  display: flex;
  justify-content: space-between;
}
.bottom-pic {
  width: 50%;
  margin-left: 10%;
}
.banner-left {
  width: 800px;
  padding-top: 200px;
  margin: 0 auto;
}
.title-w {
  width: 600px;
}
.banner-detail {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  padding: 20px 0;
}
.banner-title {
  font-size: 40px;
  font-weight: 400;
  color: #000;
  /* width:400px; */
}
.pic-left{    
  margin-right: 10%;
    margin-left: 0;

}
</style>
